<template>
<div>
    <b-row>
        <b-col md="12">
            <b-row>
                <b-col md="12">
                    <CCard>
                        <CCardHeader color="primario" text-color="white">
                            <i class="fas fa-file-alt fa-md mr-1"></i>
                            <span class="h5">Gestión de estudio conflictos de intereses solicitantes</span>
                        </CCardHeader>
                        <CCardBody>
                            <b-row>
                                <b-col md="12">
                                    <validation-observer ref="observer" v-slot="{ handleSubmit }">
                                        <b-form @submit.stop.prevent="handleSubmit(guardarEstudioConflicto)">
                                            <b-row>
                                                <!-- <b-col md="6">
                                                    <validation-provider name="empresa" rules="required" v-slot="{ errors }">
                                                        <b-form-group label="Empresa:" class="mb-2">
                                                            <v-select :reduce="(listaClientes) => listaClientes.idAuditoria" label="razonSocial" placeholder="Seleccione una opción" :class="{'style-valid-select': datosConflictoInteres.idAuditoria,'style-invalid-select is-invalid': !!errors.length,}" v-model.lazy="datosConflictoInteres.idAuditoria" :options="listaClientes">
                                                                <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                            </v-select>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col> -->
                                                <b-col md="4">
                                                    <validation-provider name="ciudad" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                        <b-form-group label="Ciudad:">
                                                            <b-form-input :state="getValidationState(validationContext)" type="text" placeholder="Ingrese la ciudad" v-model.lazy="datosConflictoInteres.ciudad"></b-form-input>
                                                            <b-form-invalid-feedback>{{validationContext.errors[0]}}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="4">
                                                    <validation-provider name="fecha" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                        <b-form-group label="Fecha:">
                                                            <b-form-input :state="getValidationState(validationContext)" type="date" v-model.lazy="datosConflictoInteres.fecha"></b-form-input>
                                                            <b-form-invalid-feedback>{{validationContext.errors[0]}}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="4">
                                                    <validation-provider name="nombre" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                        <b-form-group label="Nombre:">
                                                            <b-form-input :state="getValidationState(validationContext)" type="text" placeholder="Ingrese el nombre" v-model.lazy="datosConflictoInteres.nombre"></b-form-input>
                                                            <b-form-invalid-feedback>{{validationContext.errors[0]}}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider name="cargo" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                        <b-form-group label="Cargo:">
                                                            <b-form-input :state="getValidationState(validationContext)" type="text" placeholder="Ingrese el cargo" v-model.lazy="datosConflictoInteres.cargo"></b-form-input>
                                                            <b-form-invalid-feedback>{{validationContext.errors[0]}}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider name="solicitud" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                        <b-form-group label="Solicitud:">
                                                            <b-form-input :state="getValidationState(validationContext)" type="text" placeholder="Ingrese la solicitud" v-model.lazy="datosConflictoInteres.solicitud"></b-form-input>
                                                            <b-form-invalid-feedback>{{validationContext.errors[0]}}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="12" class=" my-2">
                                                    <span class="text-muted h5"><u>Actividades del solicitante</u></span>
                                                </b-col>
                                                <b-col md="12">
                                                    <validation-provider name="detalle" v-slot="validationContext">
                                                        <b-form-group label="1. Indique otras actividades que realice y que sean diferentes a las que se refieren con el Sistema de Gestión que tiene como objetivo certificar" class="mb-2">
                                                            <b-form-textarea :state="getValidationState(validationContext)" rows="3" max-rows="6" placeholder="Ingrese el detalle " v-model.lazy="datosConflictoInteres.detalleActividades"></b-form-textarea>
                                                            <b-form-invalid-feedback>{{validationContext.errors[0]}}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="12 my-2">
                                                    <span>2. Indique los nexos que tiene o ha tenido con otros organismos de
                                                        certificación ó actividades relacionadas con asesorías, consultorías
                                                        y/o entrenamiento:</span>
                                                    <b-button size="sm" variant="dark" class="float-right" @click="agregarPuntoDos">
                                                        <i class="fas fa-plus fa-sm"></i><span> Agregar</span>
                                                    </b-button>
                                                </b-col>
                                                <b-col md="12" class="mt-2">
                                                    <b-table bordered hover show-empty mediun responsive outlined :items="datosConflictoInteres.puntoDos" :fields="campoPuntoDos" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                        <template #cell(acciones)="param">
                                                            <b-button @click="eliminarPunto(param, datosConflictoInteres.puntoDos)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                <span class="btn-inner--icon">
                                                                    <i class="fas fa-trash-alt my-0 mx-0"></i>
                                                                </span>
                                                            </b-button>
                                                        </template>

                                                        <template v-slot:cell(organizacionPersonal)="row">
                                                            <validation-provider :name="'organizacionPersonal-' + row.index" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                                <b-form-group>
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la organización o personal" :value="row.item.organizacionPersonal" @change="row.item.organizacionPersonal = $event"></b-form-input>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </template>

                                                        <template v-slot:cell(describaRelacion)="row">
                                                            <validation-provider :name="'describaRelacion-' + row.index" v-slot="validationContext">
                                                                <b-form-group>
                                                                    <b-form-textarea :state="getValidationState(validationContext)" rows="3" max-rows="6" placeholder="Ingrese la relación " :value="row.item.describaRelacion" @change="row.item.describaRelacion = $event"></b-form-textarea>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </template>

                                                        <template v-slot:cell(fecha)="row">
                                                            <validation-provider :name="'fecha-' + row.index" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                                <b-form-group>
                                                                    <b-form-input :state="getValidationState(validationContext)" type="date" v-model.lazy="row.item.fecha"></b-form-input>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </template>
                                                    </b-table>
                                                </b-col>
                                                <b-col md="9">
                                                    <b-form-group label="3.   Ha recibido consultoría y/o asesoría de algún miembro del IQC PERU S.A.C.?" class="mb-2">
                                                        <b-form-radio-group v-model.lazy="datosConflictoInteres.isPuntoTres" :options="[{ value: 1, text: 'SI' },{ value: 2, text: 'NO' },]" small plain></b-form-radio-group>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col md="3" v-if="datosConflictoInteres.isPuntoTres == 1">
                                                    <b-button size="sm" variant="dark" class="float-right" @click="agregarPuntoTres">
                                                        <i class="fas fa-plus fa-sm"></i><span> Agregar</span>
                                                    </b-button>
                                                </b-col>
                                                <b-col md="12" class="mt-2" v-if="datosConflictoInteres.isPuntoTres == 1">
                                                    <b-table bordered hover show-empty mediun responsive outlined :items="datosConflictoInteres.puntoTres" :fields="campoPuntoTres" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                        <template #cell(acciones)="param">
                                                            <b-button @click="eliminarPunto(param, datosConflictoInteres.puntoTres)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                <span class="btn-inner--icon">
                                                                    <i class="fas fa-trash-alt my-0 mx-0"></i>
                                                                </span>
                                                            </b-button>
                                                        </template>

                                                        <template v-slot:cell(funcionario)="row">
                                                            <validation-provider :name="'funcionario-' + row.index" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                                <b-form-group>
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el funcionario" :value="row.item.funcionario" @change="row.item.funcionario = $event"></b-form-input>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </template>

                                                        <template v-slot:cell(descripcionConsultoria)="row">
                                                            <validation-provider :name="'descripcionConsultoria-' + row.index" v-slot="validationContext">
                                                                <b-form-group>
                                                                    <b-form-textarea :state="getValidationState(validationContext)" rows="3" max-rows="6" placeholder="Ingrese la descripción " :value="row.item.descripcionConsultoria" @change="row.item.descripcionConsultoria = $event"></b-form-textarea>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </template>

                                                        <template v-slot:cell(fecha)="row">
                                                            <validation-provider :name="'fecha-' + row.index" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                                <b-form-group>
                                                                    <b-form-input :state="getValidationState(validationContext)" type="date" v-model.lazy="row.item.fecha"></b-form-input>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </template>
                                                    </b-table>
                                                </b-col>

                                                <b-col md="9">
                                                    <b-form-group label="4.   Ha realizado auditorías internas dentro de la organización con algún miembro del IQC PERU S.A.C.?" class="mb-2">
                                                        <b-form-radio-group v-model.lazy="datosConflictoInteres.isPuntoCuatro" :options="[{ value: 1, text: 'SI' },{ value: 2, text: 'NO' },]" small plain></b-form-radio-group>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col md="3" v-if="datosConflictoInteres.isPuntoCuatro == 1">
                                                    <b-button size="sm" variant="dark" class="float-right" @click="agregarPuntoCuatro">
                                                        <i class="fas fa-plus fa-sm"></i><span> Agregar</span>
                                                    </b-button>
                                                </b-col>
                                                <b-col md="12" class="mt-2" v-if="datosConflictoInteres.isPuntoCuatro == 1">
                                                    <b-table bordered hover show-empty mediun responsive outlined :items="datosConflictoInteres.puntoCuatro" :fields="campoPuntoCuatro" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                        <template #cell(acciones)="param">
                                                            <b-button @click="eliminarPunto(param, datosConflictoInteres.puntoCuatro)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                <span class="btn-inner--icon">
                                                                    <i class="fas fa-trash-alt my-0 mx-0"></i>
                                                                </span>
                                                            </b-button>
                                                        </template>

                                                        <template v-slot:cell(funcionario)="row">
                                                            <validation-provider :name="'funcionario-' + row.index" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                                <b-form-group>
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el funcionario" :value="row.item.funcionario" @change="row.item.funcionario = $event"></b-form-input>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </template>

                                                        <template v-slot:cell(descripcionAuditoria)="row">
                                                            <validation-provider :name="'describaAuditoria-' + row.index" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                                <b-form-group>
                                                                    <b-form-textarea :state="getValidationState(validationContext)" rows="3" max-rows="6" placeholder="Ingrese la descripción " :value="row.item.descripcionAuditoria" @change="row.item.descripcionAuditoria = $event"></b-form-textarea>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </template>

                                                        <template v-slot:cell(fecha)="row">
                                                            <validation-provider :name="'fecha-' + row.index" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                                <b-form-group>
                                                                    <b-form-input :state="getValidationState(validationContext)" type="date" v-model.lazy="row.item.fecha"></b-form-input>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </template>
                                                    </b-table>
                                                </b-col>
                                                <b-col md="12 my-2">
                                                    <span>5. Especifique si usted o algún miembro de su organización tiene
                                                        algún grado de consanguineidad con funcionarios del IQC PERU
                                                        S.A.C.</span>
                                                    <b-button size="sm" variant="dark" class="float-right" @click="agregarPuntoCinco">
                                                        <i class="fas fa-plus fa-sm"></i><span> Agregar</span>
                                                    </b-button>
                                                </b-col>
                                                <b-col md="12" class="mt-2">
                                                    <b-table bordered hover show-empty mediun responsive outlined :items="datosConflictoInteres.puntoCinco" :fields="campoPuntoCinco" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                        <template #cell(acciones)="param">
                                                            <b-button @click="eliminarPunto(param, datosConflictoInteres.puntoCinco)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                <span class="btn-inner--icon">
                                                                    <i class="fas fa-trash-alt my-0 mx-0"></i>
                                                                </span>
                                                            </b-button>
                                                        </template>

                                                        <template v-slot:cell(funcionario)="row">
                                                            <validation-provider :name="'funcionario-' + row.index" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                                <b-form-group>
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el funcionario" :value="row.item.organizacionPersonal" @change="row.item.organizacionPersonal = $event"></b-form-input>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </template>

                                                        <template v-slot:cell(describaConsaguineidad)="row">
                                                            <validation-provider :name="'describaConsaguineidad-' + row.index" v-slot="validationContext">
                                                                <b-form-group>
                                                                    <b-form-textarea :state="getValidationState(validationContext)" rows="3" max-rows="6" placeholder="Ingrese la descripción " :value="row.item.describaConsaguineidad" @change="row.item.describaConsaguineidad = $event"></b-form-textarea>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </template>
                                                        <template v-slot:cell(nombreOrganizacionSolicitante)="row">
                                                            <validation-provider :name="'nombreOrganizacionSolicitante-' + row.index" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                                <b-form-group>
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la organización" :value="row.item.nombreOrganizacionSolicitante" @change="row.item.nombreOrganizacionSolicitante = $event"></b-form-input>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </template>
                                                    </b-table>
                                                </b-col>
                                                <b-col md="12 my-2">
                                                    <span>6. Especifique si usted o algún miembro de su organización tiene o ha
                                                        tenido relaciones de tipo comercial o laboral con funcionarios de IQC
                                                        PERU S.A.C.</span>
                                                    <b-button size="sm" variant="dark" class="float-right" @click="agregarPuntoSeis">
                                                        <i class="fas fa-plus fa-sm"></i><span> Agregar</span>
                                                    </b-button>
                                                </b-col>
                                                <b-col md="12" class="mt-2">
                                                    <b-table bordered hover show-empty mediun responsive outlined :items="datosConflictoInteres.puntoSeis" :fields="campoPuntoSeis" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                        <template #cell(acciones)="param">
                                                            <b-button @click="eliminarPunto(param, datosConflictoInteres.puntoSeis)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                <span class="btn-inner--icon">
                                                                    <i class="fas fa-trash-alt my-0 mx-0"></i>
                                                                </span>
                                                            </b-button>
                                                        </template>

                                                        <template v-slot:cell(funcionario)="row">
                                                            <validation-provider :name="'funcionario-' + row.index" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                                <b-form-group>
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el funcionario" :value="row.item.funcionario" @change="row.item.funcionario = $event"></b-form-input>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </template>

                                                        <template v-slot:cell(describaRelacionComercial)="row">
                                                            <validation-provider :name="'describaRelacionComercial-' + row.index" v-slot="validationContext">
                                                                <b-form-group>
                                                                    <b-form-textarea :state="getValidationState(validationContext)" rows="3" max-rows="6" placeholder="Ingrese la relación " :value="row.item.describaRelacionComercial" @change="row.item.describaRelacionComercial = $event"></b-form-textarea>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </template>

                                                        <template v-slot:cell(fecha)="row">
                                                            <validation-provider :name="'fecha-' + row.index" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                                <b-form-group>
                                                                    <b-form-input :state="getValidationState(validationContext)" type="date" v-model.lazy="row.item.fecha"></b-form-input>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </template>

                                                        <template v-slot:cell(duracion)="row">
                                                            <validation-provider :name="'duracion-' + row.index" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                                <b-form-group>
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la duración" :value="row.item.duracion" @change="row.item.duracion = $event"></b-form-input>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </template>

                                                        <template v-slot:cell(nombrePersonaSolicitante)="row">
                                                            <validation-provider :name="'nombrePersonaSolicitante-' + row.index" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                                <b-form-group>
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la duración" :value="row.item.nombrePersonaSolicitante" @change="row.item.nombrePersonaSolicitante = $event"></b-form-input>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </template>
                                                    </b-table>
                                                </b-col>
                                                <b-col md="9">
                                                    <b-form-group label="7. Ha utilizado a la IQC PERU S.A.C. para actividades de consultoría, asesoría , investigación u otras?" class="mb-2">
                                                        <b-form-radio-group v-model.lazy="datosConflictoInteres.isPuntoSiete" :options="[{ value: 1, text: 'SI' },{ value: 2, text: 'NO' },]" small plain></b-form-radio-group>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col md="3" v-if="datosConflictoInteres.isPuntoSiete == 1">
                                                    <b-button size="sm" variant="dark" class="float-right" @click="agregarPuntoSiete">
                                                        <i class="fas fa-plus fa-sm"></i><span> Agregar</span>
                                                    </b-button>
                                                </b-col>
                                                <b-col md="12" class="mt-2" v-if="datosConflictoInteres.isPuntoSiete == 1">
                                                    <b-table bordered hover show-empty mediun responsive outlined :items="datosConflictoInteres.puntoSiete" :fields="campoPuntoSiete" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                        <template #cell(acciones)="param">
                                                            <b-button @click="eliminarPunto(param, datosConflictoInteres.puntoSiete)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                <span class="btn-inner--icon">
                                                                    <i class="fas fa-trash-alt my-0 mx-0"></i>
                                                                </span>
                                                            </b-button>
                                                        </template>

                                                        <template v-slot:cell(dependenciaPrograma)="row">
                                                            <validation-provider :name="'dependenciaPrograma-' + row.index" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                                <b-form-group>
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la dependencia o programa" :value="row.item.dependenciaPrograma" @change="row.item.dependenciaPrograma = $event"></b-form-input>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </template>

                                                        <template v-slot:cell(describaActividad)="row">
                                                            <validation-provider :name="'describaActividad-' + row.index" v-slot="validationContext">
                                                                <b-form-group>
                                                                    <b-form-textarea :state="getValidationState(validationContext)" rows="3" max-rows="6" placeholder="Ingrese la descripción" :value="row.item.describaActividad" @change="row.item.describaActividad = $event"></b-form-textarea>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </template>

                                                        <template v-slot:cell(fecha)="row">
                                                            <validation-provider :name="'fecha-' + row.index" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                                <b-form-group>
                                                                    <b-form-input :state="getValidationState(validationContext)" type="date" v-model.lazy="row.item.fecha"></b-form-input>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </template>
                                                    </b-table>
                                                </b-col>
                                                <b-col md="12">
                                                    <validation-provider name="detalle razones" v-slot="validationContext">
                                                        <b-form-group label="8. Cuáles son las razones que motivan a la organización solicitar la certificación del Sistema de Gestión?" class="mb-2">
                                                            <b-form-textarea :state="getValidationState(validationContext)" rows="3" max-rows="6" placeholder="Ingrese las razones " v-model.lazy="datosConflictoInteres.detalleRazones"></b-form-textarea>
                                                            <b-form-invalid-feedback>{{validationContext.errors[0]}}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="12" class="my-1">
                                                    <span class="text-muted">Si considera que alguna situación puede ser un conflictos de
                                                        intereses para el IQC PERU S.A.C., o que puede ser importante por
                                                        favor regístrela a continuación en el campo de observaciones.
                                                    </span>
                                                </b-col>
                                                <b-col md="12">
                                                    <validation-provider name="observaciones" v-slot="validationContext">
                                                        <b-form-group label="Observaciones:" class="mb-2">
                                                            <b-form-textarea :state="getValidationState(validationContext)" rows="3" max-rows="6" placeholder="Ingrese las observaciones " v-model.lazy="datosConflictoInteres.observaciones"></b-form-textarea>
                                                            <b-form-invalid-feedback>{{validationContext.errors[0]}}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider name="firma" v-slot="validationContext">
                                                        <b-form-group label="Firma:">
                                                            <b-input-group>
                                                                <b-input-group-prepend v-if="datosConflictoInteres.urlFirma != ''">
                                                                    <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosConflictoInteres.urlFirma)" v-c-tooltip="'Descargar'">
                                                                        <i class="fas fa-download fa-xs"></i>
                                                                    </b-button>
                                                                </b-input-group-prepend>
                                                                <b-form-file ref="file" class="text-left" v-model.lazy="datosConflictoInteres.firma" v-on:change="handleFileUpload" :state="getValidationState(validationContext)" :placeholder="datosConflictoInteres.nombreFirma? datosConflictoInteres.nombreFirma: 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="datosConflictoInteres.nombreFirma? datosConflictoInteres.nombreFirma: 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                                                <b-form-invalid-feedback>{{validationContext.errors[0]}}</b-form-invalid-feedback>
                                                            </b-input-group>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="12" class="my-1">
                                                    <span class="text-muted">A través de este documento me comprometo a reportar cualquier
                                                        situación que pueda generar un conflicto de intereses y amenazar la
                                                        imparcialidad del proceso.
                                                    </span>
                                                </b-col>
                                                <b-col md="12 text-center my-3">
                                                    <b-button :disabled="disabled" :to="{name: $route.params.eu ? 'ejecutar auditoria' : 'Documentos Stage 1'}" variant="dark" class=" mr-2">
                                                        <i class="fas fa-arrow-left"></i> Volver
                                                    </b-button>
                                                    <b-button :disabled="disabled" variant="success" type="submit">
                                                        <i class="fas fa-save"></i> Guardar
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                        </b-form>
                                    </validation-observer>
                                </b-col>
                            </b-row>
                        </CCardBody>
                    </CCard>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</div>
</template>

<script>
import axios from 'axios';
import CONSTANTES from '@/Constantes.js';

export default {
    data() {
        return {
            isTemplate: true,
            datosConflictoInteres: {
                idEstudioConflictos: null,
                idCliente: null,
                idAuditoria: null,
                fecha: null,
                cargo: '',
                solicitud: '',
                nombre: '',
                ciudad: '',
                detalleActividades: '',
                puntoDos: [],
                isPuntoTres: 1,
                puntoTres: [],
                isPuntoCuatro: 1,
                puntoCuatro: [],
                puntoCinco: [],
                puntoSeis: [],
                isPuntoSiete: 1,
                puntoSiete: [],
                detalleRazones: '',
                observaciones: '',

                firma: null,
                nombreFirma: '',
                urlFirma: '',
            },

            campoPuntoDos: [{
                    key: 'organizacionPersonal',
                    label: 'Organización o Personal',
                    class: 'text-center',
                },
                {
                    key: 'describaRelacion',
                    label: 'Describa Relación',
                    class: 'text-center',
                },
                {
                    key: 'fecha',
                    label: 'Fecha',
                    class: 'text-center',
                },
                {
                    key: 'acciones',
                    label: '',
                    class: 'text-center',
                },
            ],

            campoPuntoTres: [{
                    key: 'funcionario',
                    label: 'Funcionario',
                    class: 'text-center',
                },
                {
                    key: 'descripcionConsultoria',
                    label: 'Descripción consultoria / asesoría',
                    class: 'text-center',
                },
                {
                    key: 'fecha',
                    label: 'Fecha',
                    class: 'text-center',
                },
                {
                    key: 'acciones',
                    label: '',
                    class: 'text-center',
                },
            ],

            campoPuntoCuatro: [{
                    key: 'funcionario',
                    label: 'Funcionario',
                    class: 'text-center',
                },
                {
                    key: 'descripcionAuditoria',
                    label: 'Descripción auditoría interna alcance y sistema',
                    class: 'text-center',
                },
                {
                    key: 'fecha',
                    label: 'Fecha',
                    class: 'text-center',
                },
                {
                    key: 'acciones',
                    label: '',
                    class: 'text-center',
                },
            ],

            campoPuntoCinco: [{
                    key: 'funcionario',
                    label: 'Nombre funcionario de IQC PERÚ S.A.C',
                    class: 'text-center',
                },
                {
                    key: 'describaConsaguineidad',
                    label: 'Describa relación de consaguineidad',
                    class: 'text-center',
                },
                {
                    key: 'nombreOrganizacionSolicitante',
                    label: 'Nombre persona de la organización solicitante',
                    class: 'text-center',
                },
                {
                    key: 'acciones',
                    label: '',
                    class: 'text-center',
                },
            ],

            campoPuntoSeis: [{
                    key: 'funcionario',
                    label: 'Nombre funcionario de IQC PERÚ S.A.C',
                    class: 'text-center',
                },
                {
                    key: 'describaRelacionComercial',
                    label: 'Describa relación comercial o laboral',
                    class: 'text-center',
                },
                {
                    key: 'fecha',
                    label: 'Fecha',
                    class: 'text-center',
                },
                {
                    key: 'duracion',
                    label: 'Duración',
                    class: 'text-center',
                },
                {
                    key: 'nombrePersonaSolicitante',
                    label: 'Nombre persona de la organización solicitante',
                    class: 'text-center',
                },
                {
                    key: 'acciones',
                    label: '',
                    class: 'text-center',
                },
            ],

            campoPuntoSiete: [{
                    key: 'dependenciaPrograma',
                    label: 'Dependencia o programa de la IQC PERÚ S.A.C',
                    class: 'text-center',
                },
                {
                    key: 'describaActividad',
                    label: 'Describa actividad que realizó con la IQC PERÚ S.A.C',
                    class: 'text-center',
                },
                {
                    key: 'fecha',
                    label: 'Fecha',
                    class: 'text-center',
                },
                {
                    key: 'acciones',
                    label: '',
                    class: 'text-center',
                },
            ],

            listaClientes: [],
            disabled: false,
        };
    },
    methods: {
        descargarDocumento(url) {
            window.open(url);
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },

        handleFileUpload(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (
                    !e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)
                ) {
                    this.swat('error', 'Formato de archivo no admitido.');
                    this.$refs[`file`].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', 'El tamaño del archivo no debe ser superior a 20MB');
                    this.$refs[`file`].reset();
                    return;
                }
            }
        },

        agregarPuntoDos() {
            let me = this;
            me.datosConflictoInteres.puntoDos.push({
                idPuntoDos: Date.now(),
                organizacionPersonal: '',
                describaRelacion: '',
                fecha: !me.isTemplate ? moment().format('YYYY-MM-DD') : '',
            });
        },

        agregarPuntoTres() {
            let me = this;
            me.datosConflictoInteres.puntoTres.push({
                idPuntoTres: Date.now(),
                funcionario: '',
                descripcionConsultoria: '',
                fecha: !me.isTemplate ? moment().format('YYYY-MM-DD') : '',
            });
        },

        agregarPuntoCuatro() {
            let me = this;
            me.datosConflictoInteres.puntoCuatro.push({
                idPuntoCuatro: Date.now(),
                funcionario: '',
                descripcionAuditoria: '',
                fecha: !me.isTemplate ? moment().format('YYYY-MM-DD') : '',
            });
        },

        agregarPuntoCinco() {
            let me = this;
            me.datosConflictoInteres.puntoCinco.push({
                idPuntoCinco: Date.now(),
                funcionario: '',
                describaConsaguineidad: '',
                nombreOrganizacionSolicitante: '',
            });
        },

        agregarPuntoSeis() {
            let me = this;
            me.datosConflictoInteres.puntoSeis.push({
                idPuntoSeis: Date.now(),
                funcionario: '',
                describaRelacionComercial: '',
                fecha: !me.isTemplate ? moment().format('YYYY-MM-DD') : '',
                duracion: '',
                nombrePersonaSolicitante: '',
            });
        },

        agregarPuntoSiete() {
            let me = this;
            me.datosConflictoInteres.puntoSiete.push({
                idPuntoSiete: Date.now(),
                dependenciaPrograma: '',
                describaActividad: '',
                fecha: !me.isTemplate ? moment().format('YYYY-MM-DD') : '',
            });
        },

     

        obtenerEstudioConflicto() {
            let me = this;
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/obtener-estudio-conflicto', {
                    params: {
                        idEstudioConflictos: me.$route.params.id,
                        idCliente: me.datosConflictoInteres.idCliente,
                        stage: 1,
                    },
                })
                .then(function (response) {
                    me.datosConflictoInteres.idEstudioConflictos = response.data[0].idEstudioConflictos;
                    me.datosConflictoInteres.idCliente = response.data[0].idCliente;
                    me.datosConflictoInteres.idAuditoria = response.data[0].idAuditoria;
                    me.datosConflictoInteres.cargo = response.data[0].cargo;
                    me.datosConflictoInteres.solicitud = response.data[0].solicitud;
                    me.datosConflictoInteres.nombre = response.data[0].nombre;
                    me.datosConflictoInteres.fecha = response.data[0].fecha;
                    me.datosConflictoInteres.ciudad = response.data[0].ciudad;
                    me.datosConflictoInteres.detalleActividades = response.data[0].detalleActividades;
                    me.datosConflictoInteres.puntoDos = JSON.parse(response.data[0].puntoDos);
                    me.datosConflictoInteres.isPuntoTres = response.data[0].isPuntoTres;
                    me.datosConflictoInteres.puntoTres = JSON.parse(response.data[0].puntoTres);
                    me.datosConflictoInteres.isPuntoCuatro = response.data[0].isPuntoCuatro;
                    me.datosConflictoInteres.puntoCuatro = JSON.parse(response.data[0].puntoCuatro);
                    me.datosConflictoInteres.puntoCinco = JSON.parse(response.data[0].puntoCinco);
                    me.datosConflictoInteres.puntoSeis = JSON.parse(response.data[0].puntoSeis);
                    me.datosConflictoInteres.isPuntoSiete = response.data[0].isPuntoSiete;
                    me.datosConflictoInteres.puntoSeis = JSON.parse(response.data[0].puntoSeis);
                    me.datosConflictoInteres.detalleRazones = response.data[0].detalleRazones;
                    me.datosConflictoInteres.observaciones = response.data[0].observaciones;
                    me.datosConflictoInteres.nombreFirma = response.data[0].nombreFirma;
                    me.datosConflictoInteres.urlFirma = response.data[0].urlFirma;
               
                if (me.$route.params.eu && me.$route.query.idAuditoria) {
                        me.datosConflictoInteres.isPack = true;
                        me.datosConflictoInteres.idEstudioConflictos = me.$route.params.eu == 'u' ? me.$route.params.id : null;
                        me.datosConflictoInteres.idAuditoria = me.$route.query.idAuditoria || null
                        me.datosConflictoInteres.idUsuario = me.$store.state.user.uid;
                        me.datosConflictoInteres.sUsuario = me.$store.state.user.username;
                        me.datosConflictoInteres.eu = me.$route.params.eu == 'u' ? 'u' : 'i'
                    }
               })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                    me.$router.push({
                        name: me.$route.params.eu ?
                            'ejecutar auditoria' : 'Documentos Stage 1',
                    });
                });
        },

        eliminarPunto(param, array) {
            array.splice(param.index, 1);
        },
        guardarEstudioConflicto() {
            let me = this;
            me.disabled = true;

            const formData = new FormData();
            formData.append('folder', 'pack/documentos-stage-1/gestion-estudio-conflictos');
            formData.append('file1', me.datosConflictoInteres.firma);

            if (me.$route.params.id) {
                formData.append('idEstudioConflictos', me.datosConflictoInteres.idEstudioConflictos);
            }

            formData.append('idCliente', me.datosConflictoInteres.idCliente);
            formData.append('idAuditoria', me.datosConflictoInteres.idAuditoria);
            formData.append('fecha', me.datosConflictoInteres.fecha);
            formData.append('stage', '1');
            formData.append('cargo', me.datosConflictoInteres.cargo);
            formData.append('solicitud', me.datosConflictoInteres.solicitud);
            formData.append('nombre', me.datosConflictoInteres.nombre);
            formData.append('ciudad', me.datosConflictoInteres.ciudad);
            formData.append('detalleActividades', me.datosConflictoInteres.detalleActividades);
            formData.append('puntoDos', JSON.stringify(me.datosConflictoInteres.puntoDos));
            formData.append('isPuntoTres', me.datosConflictoInteres.isPuntoTres);
            formData.append('puntoTres', JSON.stringify(me.datosConflictoInteres.puntoTres));
            formData.append('isPuntoCuatro', me.datosConflictoInteres.isPuntoCuatro);
            formData.append('puntoCuatro', JSON.stringify(me.datosConflictoInteres.puntoCuatro));
            formData.append('puntoCinco', JSON.stringify(me.datosConflictoInteres.puntoCinco));
            formData.append('puntoSeis', JSON.stringify(me.datosConflictoInteres.puntoSeis));
            formData.append('isPuntoSiete', me.datosConflictoInteres.isPuntoSiete);
            formData.append('puntoSiete', JSON.stringify(me.datosConflictoInteres.puntoSiete));
            formData.append('detalleRazones', me.datosConflictoInteres.detalleRazones);
            formData.append('observaciones', me.datosConflictoInteres.observaciones);
            formData.append('nombreFirma', me.datosConflictoInteres.nombreFirma);
            formData.append('urlFirma', me.datosConflictoInteres.urlFirma);
            
            if (me.$route.params.eu && me.$route.query.idAuditoria) {
                formData.append('isPack', me.datosConflictoInteres.isPack);
                formData.append('idUsuario', me.datosConflictoInteres.idUsuario);
                formData.append('sUsuario', me.datosConflictoInteres.sUsuario);
                formData.append('eu', me.datosConflictoInteres.eu);
            }
          
          axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/registrar-estudios-conflicto',
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`,
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? 'success' : 'error';
                    me.swat(color, response.data.mensaje);
                    me.disabled = false;
                    me.$router.push({
                        name: me.$route.params.eu ?
                            'ejecutar auditoria' : 'Documentos Stage 1',
                    });
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                    me.disabled = false;
                });
        },

        swat(icon, title) {
            this.$swal
                .mixin({
                    toast: true,
                    showConfirmButton: false,
                    position: 'bottom-right',
                    timer: 3500,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', this.$swal.stopTimer);
                        toast.addEventListener('mouseleave', this.$swal.resumeTimer);
                    },
                })
                .fire({
                    icon: icon,
                    title: title,
                });
        },
    },

    async mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosConflictoInteres.idCliente = user.uidClient;
            // await this.listarClientes();
            if (this.$route.params.id) {
                this.obtenerEstudioConflicto();
            }
        }
    },
};
</script>
